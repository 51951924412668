import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { QrReader } from 'react-qr-reader';
import axiosInstance from "../../../services/api";
// import { BsQrCodeScan } from "react-icons/bs";
import SpinLoader from "../../../components/spinLoader/spinLoader";
import CustomInput from "../../../components/customInput/customInput";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import ImageUploaderRaw from "../../../components/imageUploader/imageUploaderRaw";
import CustomSelect from "../../../components/customSelect/customSelect";
import axios from "axios";

const conditions = [
    {
      title: "Poor",
      value: "POOR"
    },
    {
      title: "Average",
      value: "AVERAGE"
    },
    {
      title: "Good",
      value: "GOOD"
    }
  ]

const QrScanner = ({setRefetch, idx, setIdx, refetch, setTriggerClose}) => {
  const [result, setResult] = useState(idx);
  const [delay] = useState(100); // Delay can be set dynamically if needed.
  const [loading, setLoading] = useState(false);
  const [loadingu, setLoadingu] = useState(false);
  const [loadingz, setLoadingz] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [images, setImages] = useState("");

  const [assetData, setAssetData] = useState(null)
  

  const [inventory, setInventory] = useState('');
  const [msl, setMsl] = useState('');
  const [condition, setCondition] = useState('');
  const [comment, setComment] = useState('');

  const [loadingx, setLoadingx] = useState('')
  

  const [imageURL, setImageURL] = useState('');
  const [coordinates, setCoordinates] = useState(null);

  const qrRef = useRef(null);

  const toast = useToast();

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude })
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  useEffect(() => {
    fetchUserLocation()
  })

  const scanAsset = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/assets/${result}`);
      setAssetData(data?.data);
      setImageURL(data?.data?.assetsImage);
      setInventory(data?.data?.assetsInventory?.inventoryLevel)
      setMsl(data?.data?.assetsMsl?.mslLevel)
      setComment(data?.data?.assetsComment?.comment)
      setCondition(data?.data?.assetsCondition?.condition)
    } catch (error) {
        if(error?.response?.data?.message) {
            toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
        }
    } finally {
      setLoading(false);
    }
  };

  const handleImagesUpload = (images) => {
    setImages(images)
    console.log(images)
  };

  const createDetails = async () => {
    setLoadingz(true);
    // const location = fetchUserLocation()

    // const { latitude, longitude } = location

    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('PRV1TK')}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const image = images ? images?.raw[0] : imageURL?.image

    const formData = new FormData();
    formData.append('assetId', result);
    formData.append('comment', comment);
    formData.append('condition', condition);
    formData.append('mslLevel', msl);
    formData.append('inventory', inventory);
    if (!isEdit) {
      formData.append('longitude', coordinates?.longitude)
      formData.append('latitude', coordinates?.latitude)
    }
    if(!imageURL) {
      formData.append('image', image);
    }

    try {
      await axiosInstance.post('/assets/enter/asset-details', formData);
      toast({
        description: 'Information submitted succesfully',
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random());
      setIdx('');
    } catch (error) {
      const message = error?.response?.data?.message || 'Something went wrong';
      if(error?.response?.data?.message) {
        toast({
          title: "An Error Occurred",
          description: message,
          status: "error",
        });
      }
    } finally {
      setLoadingz(false);
    }
  };

 
  // const handleImagesUpload = (images) => {
  //   const formData = new FormData()
  //   formData.append('image', images?.raw[0])
  //   formData.append('assetId', result)
  //   formData.append('latitude', coordinates?.latitude)
  //   formData.append('longitude', coordinates?.longitude)

  //   const formData2 = new FormData()
  //   formData2.append('image', images?.raw[0])
  //   formData2.append('assetId', result)

  //   setImageURL(images?.urls[0])
  //   if(imageURL === null) {
  //       handleSubmit('upload', formData, '/assets/upload/image')
  //   } else {
  //       handleUpdate('update', formData2, `/assets/update/image/${assetData?.assetsImage?._id}`)
  //   }
    
  // };

  useEffect(() => {
    if (result !== "") {
      scanAsset();
    }
  }, [result, refetch]);

  const previewStyle = {
    height: 150,
    width: "100%",
    backgroundColor: "",
    border: "1px dashed gray",
    borderRadius: "6px",
    cursor: "pointer",
  };


  const close = () => {
    console.log('fire')
    setTriggerClose('close' + (Math.random()));
  }

  return (
    <>
      {result === "" ? (
        <Box position={"relative"}>
          {/* <BsQrCodeScan
            fontSize={"4rem"}
            opacity={".7"}
            style={{ position: "absolute", top: "12%", left: "43%" }}
          />
          <Text
            position={"absolute"}
            left={"39.5%"}
            fontSize={".85rem"}
            opacity={".7"}
            fontWeight={"600"}
            color={"#333"}
            bottom={"8%"}
          >
            Scan QR Code
          </Text> */}
          <QrReader
            ref={qrRef}
            delay={delay}
            style={previewStyle}
            onResult={(result, error) => {
              if (!!result) {
                setResult(result?.text);
              }
              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{facingMode: "environment"}}
          />
          <p>{result}</p>
        </Box>
      ) : loading ? (
        <SpinLoader mt="1rem" />
      ) :
      assetData &&
       (
        <>
         <Flex borderRadius={"6px"} gap=".5rem" alignItems={"center"} border="1px solid lightgray" mb="1rem" p=".5rem">
            <Box height={"70px"} width={"80px"} borderRadius={"4px"} backgroundImage={assetData?.asset?.image} backgroundSize={"cover"} backgroundPosition={"center"} />
            <Box>
                <Text fontSize={".9rem"}>Brand: <strong style={{textTransform: 'capitalize', opacity: '.7'}}>{assetData?.asset?.brand}</strong></Text>
                <Text fontSize={".9rem"}>Category: <strong style={{textTransform: 'capitalize', opacity: '.7'}}>{assetData?.asset?.category}</strong></Text>
                <Text fontSize={".9rem"}>Type: <strong style={{textTransform: 'capitalize', opacity: '.7'}}>{assetData?.asset?.type}</strong></Text>
            </Box>
         </Flex>


          <Flex mb=".8rem" gap=".5rem">
            {imageURL?.image !== "" && (
              <Box
              backgroundColor={"lightgray"}
                backgroundImage={images ? images?.urls[0] : imageURL?.image}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <ImageUploaderRaw
              onImagesUpload={handleImagesUpload}
              setLoading={setLoadingu}
              loading={loadingu}
              max={1}
              src="camera"
            />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
            <CustomInput
                type={"tel"}
                placeholder={"Inventory"}
                onChange={e => setInventory(e.target.value)}
                mb="0"
                mt="0"
                value={inventory}
            />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
            <CustomInput
                type={"tel"}
                placeholder={"MSL"}
                onChange={e => setMsl(e.target.value)}
                mb="0"
                mt="0"
                value={msl}
            />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
          <CustomSelect
            required
            options={[...conditions?.filter(x => x.value?.toLowerCase() === condition?.toLowerCase()), ...conditions?.filter(x => x.value?.toLowerCase() !== condition?.toLowerCase())]}
            // placeholder={condition}
            onChange={e => setCondition(e.target.value)}
            value={condition}
          />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
            <CustomInput
                type={"text"}
                placeholder={"Comment"}
                onChange={e => setComment(e.target.value)}
                mb="0"
                mt="0"
                value={comment}
            />
          </Flex>
          
          <Flex justifyContent={"center"} mt={"1rem"}>
            <PrimaryButton onClick={createDetails} width="200px" py="1.563rem" borderRadius="6px" text={'Submit'} loading={loadingz} />
          </Flex>
        </>
        
      )}
    </>
  );
};

export default QrScanner;

// 674092899b619d0982c4892c