import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import CustomInput from "../../../components/customInput/customInput";
import CustomSelect from "../../../components/customSelect/customSelect";
import statesLgaJson from "../../../utils/stateLGAs";
import axiosInstance from "../../../services/api";
import CustomTextArea from "../../../components/customTextArea/customTextArea";
import ImageUploaderRaw from "../../../components/imageUploader/imageUploaderRaw";
import countriesData from "../../../utils/countries.json";
import SearchableSelect from '../../../components/searchableSelect/searchableSelect';
import axios from "axios";
import classes from './createdAssets.module.css'

const url = process.env.REACT_APP_BASE_URL;


const CreateAssets = ({ setTriggerClose, setRefetch, isEdit, idx, idData }) => {
  console.log(idx, idData);
  const [streetName, setStreetName] = useState(
    idData?.addressDetails?.streetName
  );
  const [storeType, setStoreType] = useState(idData?.category);
  const [storeLevel, setStoreLevel] = useState(idData?.type);
  const [streetNumber, setStreetNumber] = useState(
    idData?.addressDetails?.buildingNo
  );
  const [coordinates, setCoordinates] = useState(null);
  const [category, setCategory] = useState(idData?.brand);
  const [imageURL, setImageURL] = useState(idData?.image);
  const [images, setImages] = useState("");
  const [state, setState] = useState(idData?.addressDetails?.state);
  const [area, setArea] = useState(idData?.addressDetails?.LGA);
  const [town, setTown] = useState(idData?.addressDetails?.city);
  const [country, setCountry] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState(idData?.additionalInfo);

  const [defaultCountry, setDefaultCountry] = useState(null);
    const [auto, setAuto] = useState(isEdit ? idData?.addressDetails?.locationName !== '' ? false : true : true)
  const [location, setLocation] = useState({value: idData?.outlet?._id})
  const [outlet, setOutlet] = useState('')
  const [buildingNo, setBuildingNo] = useState(idData?.addressDetails?.buildingNo)
  const [locationName, setLocationName] = useState(idData?.addressDetails?.locationName)

  const [client, setClient] = useState(idData?.client);
  const [clients, setClients] = useState([]);
  const [loadingx, setLoadingx] = useState(false);
  const [loading, setLoading] = useState("");
  const [loadingu, setLoadingu] = useState("");
  const [loadingv, setLoadingv] = useState("");

  const [categories, setCategories] = useState([]);
  const [searchVal, setSearchVal] = useState('')
  const [stores, setStores] = useState([])
  const [locations, setLocations] = useState([])

  const fileInputRefs = useRef({});
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);

  const [showCamera, setShowCamera] = useState(false);

  const toast = useToast();

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
        },
        (error) => {
          console.error("Error fetching user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  useEffect(() => {
    // Fetch the user's country using ipinfo.io
    fetch("https://ipinfo.io/json?token=1920c15b6512bf")
      .then((response) => response.json())
      .then((data) => {
        const countryName = data.country; // ISO country code
        // const countryFullName = data.country_name || countryName; // Full country name (depends on the service used)
        // console.log(countryName);
        // Format the response
        setDefaultCountry(countryName);
      })
      .catch((error) => console.error("Error fetching location:", error));
  }, []);

  const getCategories = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/assets/fetch/pre-name?client=${client}`
      );
      setCategories(data?.data?.names);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (client) {
      getCategories();
    }
  }, [client]);

  const categoriesx = categories?.map((i) => ({
    title: i?.brand,
    value: i?.brand,
    listValue: i?.categories?.map((v) => ({
      title: v?.category,
      value: v?.category,
      listValue: v?.types?.map((k) => ({
        title: k,
        value: k,
      })),
    })),
  }));

  const getClients = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.get("/assets/field/retrieve");
      setClients(data?.data?.organizations);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoadingx(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

    useEffect(() => {
    getClients()
  }, [])

  const getStores = async() => {
    setLoading(true)
    if(searchVal?.length >= 3) {
       try {
      const {data} = await axiosInstance.get(`/stores?search=${searchVal}`)
      setStores(data?.data?.slice(0, 10))
    } catch(error) {
    } finally {
      setLoading(false)
    }
    }
   
  }

  useEffect(() => {
    getStores()
  }, [searchVal])

  const getLocations = async() => {
    setLoading(true)
    // if(searchVal?.length >= 3) {
       try {
      const {data} = await axiosInstance.get(searchVal ?  `/assets/locations/names?status=approved&search=${searchVal}` : `/assets/locations/names?status=approved`)
      setLocations(data?.data?.assetLocations?.slice(0, 10))
    } catch(error) {
    } finally {
      setLoading(false)
    }
    // }
   
  }

  useEffect(() => {
    getLocations()
  }, [searchVal])

  const handleImagesUpload = (images) => {
    setImageURL(images?.urls[0]);
    setImages(images?.raw[0]);
    console.log(images);
  };

  const createStore = async (e) => {
    e.preventDefault();
    setLoadingv(true);

    //     const locationx =  fetchUserLocation()

    // const { latitude, longitude } = locationx

    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('PRV1TK')}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    if(!isEdit) {
      formData.append('client', client);
      formData.append('image', images);
    }
    formData.append('brand', category);
    formData.append('type', storeLevel);
    formData.append('category', storeType);
    formData.append('additionalInfo', additionalInfo || 'nil');
    if (!isEdit) {
      formData.append('longitude', coordinates?.longitude)
    formData.append('latitude', coordinates?.latitude)
    }
    
    if(!auto && !isEdit) {
      formData.append('buildingNo', buildingNo)
      formData.append('streetName', streetName)
      formData.append('country', country || countries?.find(x => x?.code === defaultCountry)?.title)
      formData.append('city', town)
      formData.append('LGA', area)
      formData.append('state', state)
      formData.append('locationName', locationName);
    }
    if(auto && !isEdit) {
      if(location?.src === 'outlet') {
        formData.append('outlet', location?.value);
      } else {
        formData.append('assetLocation', location?.value);
      }
    }
    if (isEdit) {
      formData.append('outlet', idData?.addressDetails?._id)
    }


    try {
      const { data } = isEdit
        ? await axios.patch(`${url}/assets/edit/${idx}`, formData, config)
        : await axios.post(`${url}/assets/field/register`, formData, config);
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setImageURL('')
      setTriggerClose("close" + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoadingv(false);
    }
  };

  const clientOptions = clients?.map((i) => ({
    title: i?.client?.companyName,
    value: i?.client?._id,
  }));

  const countries = countriesData?.map((x) => ({
    title: x?.name,
    value: x?.name?.toLowerCase(),
    code: x?.code2,
    states: x?.states,
  }));

  const places2 =
    countries
      ?.find((x) => x?.value === (country || "nigeria"))
      ?.states?.map((i) => ({
        title: i?.name,
        value: i?.name?.toLowerCase(),
        listValue: i?.subdivision?.map((i) => ({
          title: i,
          value: i,
        })),
      })) || [];

  const teamOptions = [];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }

  const arr1 = categoriesx?.find((i) => i?.value === category);
  const arr2 = arr1?.listValue?.find((i) => i?.value === storeType);

    const storeOptions = stores?.map(x => ({
      label: x?.name,
      value: x?._id,
      src: 'outlet'
    }))
    const locationOptions = locations?.map(x => ({
      label: x?.locationName,
      value: x?._id,
      src: 'locationName'
    }))

    const handleInputChange = (value) => {
    // console.log(value)
    setSearchVal(value)
      if(value?.length < 3 || value === "") {
        setStores([])
      }
      
  }


    useEffect(() => {
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        await videoRef.current.play();
      } catch (err) {
        console.error('Error accessing camera: ', err);
      }
    };

    if (showCamera) {
      getCameraStream();
    } else {
      stopStream();
    }

    return () => stopStream();
  }, [showCamera]);


  const pickLiveImageFile = (name, file) => {
    setImages(file)
    // setValues((prev) => ({
    //   ...prev,
    //   [name]: file
    // }));
    setShowCamera(false);
    stopStream();
  };

  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };


  const takePicture = (name) => {
    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;

    canvasRef.current.width = width;
    canvasRef.current.height = height;

    const ctx = canvasRef.current.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0, width, height);

    setHasPhoto(true);

    canvasRef.current.toBlob(blob => {
      const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
      pickLiveImageFile(name, file);
    }, 'image/jpeg');
  };

  const clearPhoto = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setHasPhoto(false);
  };

  useEffect(() => {
    if (showCamera) {
      const constraints = {
        video: {
          facingMode: { exact: "environment" }
        }
      };

      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        })
        .catch(err => {
          console.error("Error accessing camera: ", err);
        });
    }
  }, [showCamera]);


  return (
    <div className={classes.bigContainer}>
      <div className={classes.formContainer}>
    {/* <form> */}
      <Box>
        <Box opacity={showCamera ? 0 : 1}>
        {!isEdit &&
          <CustomSelect
          required={isEdit ? false : true}
          options={clientOptions}
          placeholder={
            isEdit
              ? clientOptions?.find((i) => i?.value === client)?.title
              : "Client"
          }
          onChange={(e) => setClient(e.target.value)}
          mb=".8rem"
        />}
        <CustomSelect
          required={isEdit ? false : true}
          options={categoriesx}
          placeholder={isEdit ? category : "Brand"}
          onChange={(e) => setCategory(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required={isEdit ? false : true}
          options={
            categoriesx?.filter((i) => i.value === category)[0]?.listValue
          }
          placeholder={isEdit ? storeType : "Category"}
          onChange={(e) => setStoreType(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required={isEdit ? false : true}
          options={arr2?.listValue}
          placeholder={isEdit ? storeLevel : "Type"}
          onChange={(e) => setStoreLevel(e.target.value)}
          mb=".8rem"
        />
        {auto ? 
        <Box>
          <SearchableSelect
                        options={[...storeOptions, ...locationOptions]}
                        onChange={(e) => setLocation(e)}
                        onInputChange={handleInputChange}
                        placeholder={'Select Outlet'}
                        name="locationName"
                        height="300px"
                    />
                    <Flex justifyContent={"flex-start"}>
                      <Text fontSize={".8rem"} mt=".3rem">Cant find your location? <span onClick={() => setAuto(false)} style={{fontWeight: '500', cursor: "pointer", textDecoration: 'underline'}}>Add new</span></Text>
                    </Flex>
        </Box>
              :
              !isEdit &&
        <>
          <CustomInput
            type={"text"}
            required={isEdit ? false : true}
            value={locationName}
            placeholder={"Location name"}
            onChange={(e) => setLocationName(e.target.value)}
            mb=".8rem"
          />
          <Flex gap={".8rem"}>
          <CustomInput
            type={"number"}
            placeholder={"Building Number"}
            onChange={(e) => setBuildingNo(e.target.value)}
            mb=".8rem"
            value={buildingNo}
          />

          <CustomInput
            type={"text"}
            required={isEdit ? false : true}
            value={streetName}
            placeholder={"Street name"}
            onChange={(e) => setStreetName(e.target.value)}
            mb=".8rem"
          />
        </Flex>

        <Flex mb=".8rem" gap={".8rem"}>
          <CustomSelect
            required={isEdit ? false : true}
            options={[
              {
                title: countries?.find((x) => x?.code === defaultCountry)
                  ?.title,
                value: countries?.find((x) => x?.code === defaultCountry)
                  ?.value,
              },
              ...countries,
            ]}
            onChange={(e) => setCountry(e.target.value)}
          />
          <CustomSelect
            required={isEdit ? false : true}
            options={places2}
            onChange={(e) => setState(e.target.value)}
            placeholder={isEdit ? state : "State"}
          />
        </Flex>

        <Flex mb=".8rem" gap={".8rem"}>
          <CustomSelect
            required={isEdit ? false : true}
            options={places2?.filter((i) => i.value === state)[0]?.listValue}
            placeholder={isEdit ? area : "Area"}
            onChange={(e) => setArea(e.target.value)}
          />
          <CustomInput
            type={"text"}
            required={isEdit ? false : true}
            placeholder={isEdit ? town : "Town"}
            onChange={(e) => setTown(e.target.value)}
            value={town}
          />
        </Flex>

        <CustomTextArea
          rows="1"
          mt="0"
          placeholder={"Additional Information"}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          mb=".8rem"
          label="Additional Information"
          value={additionalInfo}
        />
        </>       

        }
        </Box>

        {/* <Flex my="1rem" gap=".5rem">
          {imageURL !== "" && (
            <Box
              backgroundColor={"lightgray"}
              backgroundImage={imageURL}
              backgroundSize={"cover"}
              height={"100px"}
              width={"120px"}
              borderRadius={"4px"}
            />
          )}
          <ImageUploaderRaw
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingu}
            loading={loadingu}
            max={1}
            src="camera"
            required={isEdit ? false : true}
          />
        </Flex> */}


             


        
      </Box>
      {!isEdit ?
              <div>
              <div className={classes.inputContainerCon} style={{marginTop: '1rem'}}>
                {!showCamera &&
                <div>
                  <label className={classes.label}>Image </label>
               <p onClick={() => setShowCamera(true)} className={classes.pick_location}>
                 Click To Open Camera
               </p>
                </div>}
               
               {showCamera && (
                 <div className={classes.container}>
                   <div className={classes.video_container}>
                     <video className={classes.video} ref={videoRef} playsInline autoPlay muted />
                     <button className={classes.camera_icon_container} onClick={() => takePicture('image')}>Take Picture</button>
                   </div>
                 </div>
               )}
               </div>

{showCamera && <button className={classes.camera_icon_container} onClick={() => takePicture('image')}>Take Picture</button>}
         
               <canvas className={classes.taken_picture} ref={canvasRef} style={{ display: hasPhoto ? 'block' : 'none' }} />                     
             </div>
             :
             <Box mt="1rem" height={"150px"} width={"full"} backgroundColor={"lightgray"} backgroundImage={imageURL} backgroundPosition={"center"} backgroundSize={"cover"} />
             }
      <PrimaryButton
        opacity={showCamera ? 0 : 1}
        mt="1rem"
        text={`${isEdit ? "Edit" : "Add"} Asset`}
        icon="add"
        onClick={createStore}
        loading={loadingv}
      />
    {/* </form> */}
    </div>
    </div>
  );
};

export default CreateAssets;




















// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useRef, useState } from 'react'
// import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
// import CustomSelect from '../../../components/customSelect/customSelect';
// import statesLgaJson from '../../../utils/stateLGAs';
// import axiosInstance from '../../../services/api';
// import axios from 'axios';
// import classes from './createdAssets.module.css'
// import countriesData from '../../../utils/countries.json'
// import CustomInput from '../../../components/customInput/customInput';
// import SearchableSelect from '../../../components/searchableSelect/searchableSelect';
// import { useSelector } from 'react-redux';


// const url = process.env.REACT_APP_BASE_URL;

// const CreateAssets = ({ setTriggerClose, setRefetch, isEdit, idx, idData }) => {
//   console.log(idx, idData)
//   const [streetName, setStreetName] = useState(idData?.addressDetails?.streetName);
//   const [storeType, setStoreType] = useState(idData?.category);
//   const [storeLevel, setStoreLevel] = useState(idData?.type);
//   const [streetNumber, setStreetNumber] = useState(idData?.addressDetails?.buildingNo);
//   const [coordinates, setCoordinates] = useState(null);
//   const [category, setCategory] = useState(idData?.category);
//   const [imageURL, setImageURL] = useState(idData?.image);
//   const [images, setImages] = useState("");
//   const [state, setState] = useState(idData?.addressDetails?.state);
//   const [area, setArea] = useState(idData?.addressDetails?.LGA);
//   const [town, setTown] = useState(idData?.addressDetails?.city);
//   const [country, setCountry] = useState(idData?.addressDetails?.country);
//   const [additionalInfo, setAdditionalInfo] = useState(idData?.additionalInfo);
//   const [searchVal, setSearchVal] = useState('')

//   const [client, setClient] = useState(idData?.client);
//   const [brand, setBrand] = useState(idData?.brand)
//   const [type, setType] = useState(idData?.type)
//   const [clients, setClients] = useState([]);
//   const [loadingx, setLoadingx] = useState(false);
//   const [loading, setLoading] = useState('');
//   const [loadingu, setLoadingu] = useState('');
//   const [loadingv, setLoadingv] = useState('');

//   const [defaultCountry, setDefaultCountry] = useState(null);
//   const [auto, setAuto] = useState(true)
//   const [location, setLocation] = useState()
//   const [outlet, setOutlet] = useState('')


//   const [names, setNames] = useState([]);
//   const [stores, setStores] = useState([])

//   const toast = useToast();

//   const defaultState = { client: idData?.client, brand: idData?.brand, category: idData?.category, type: idData?.type, image: idData?.image, additionalInfo: idData?.additionalInfo, buildingNo: idData?.addressDetails?.buildingNo, country: idData?.addressDetails?.country,  streetName: idData?.addressDetails?.streetName, city: idData?.addressDetails?.city, LGA: idData?.addressDetails?.LGA, state: idData?.addressDetails?.state, locationName: idData?.addressDetails?.locationName };

//   const [values, setValues] = useState(defaultState);
//   const [creatingAsset, setCreatingAsset] = useState(false);

//   const currentUser = useSelector(({userData})=>  userData?.currentUser);



//   const fetchUserLocation = async () => {
//     if ('geolocation' in navigator) {
//       return new Promise((resolve, reject) => {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             resolve(position.coords);
//           },
//           (error) => {
//             console.error('Error fetching user location:', error);
//             reject(error);
//           }
//         );
//       });
//     } else {
//       console.error('Geolocation is not available in this browser.');
//       throw new Error('Geolocation is not available');
//     }
//   };

//    useEffect(() => {
//       fetch("https://ipinfo.io/json?token=1920c15b6512bf")
//         .then((response) => response.json())
//         .then((data) => {
//           const countryName = data.country;
//           console.log(countryName)
//           setDefaultCountry(countryName);
//         })
//         .catch((error) => console.error("Error fetching location:", error));
//     }, []);



//   const getCategories = async () => {
//     setLoading(true)
//     try {
//       const { data } = await axiosInstance.get(`/assets/fetch/pre-name?client=${values?.client}`)
//       setNames(data?.data?.names)
//       console.log(data?.data?.names)
//     } catch (error) {
//       console.error(error?.response?.data?.message)
//     } finally {
//       setLoading(false)
//     }
//   }

//   useEffect(() => {
//     if (values?.client) {
//       getCategories()
//     }
//   }, [values?.client])



//   const getClients = async () => {
//     setLoadingx(true)
//     try {
//       const { data } = await axiosInstance.get('/assets/field/retrieve')
//       setClients(data?.data?.organizations)
//     } catch (error) {
//       console.error(error?.response?.data?.message)
//     } finally {
//       setLoadingx(false)
//     }
//   }

//   useEffect(() => {
//     getClients()
//   }, [])

//   const getStores = async() => {
//     setLoading(true)
//     if(searchVal?.length >= 3) {
//        try {
//       const {data} = await axiosInstance.get(`/stores?search=${searchVal}`)
//       console.log(data?.data)
//       setStores(data?.data?.slice(0, 10))
//     } catch(error) {
//     } finally {
//       setLoading(false)
//     }
//     }
   
//   }

//   useEffect(() => {
//     getStores()
//   }, [searchVal])

//   const handleImagesUpload = (images) => {
//     setImageURL(images?.urls[0])
//     setImages(images?.raw[0])
//     console.log(images)
//   };

 


//   const clientOptions = clients?.map(i => ({
//     title: i?.client?.companyName,
//     value: i?.client?._id
//   }))

//   const countries = countriesData?.map(x => ({
//     title: x?.name,
//     value: x?.name?.toLowerCase(),
//     code: x?.code2,
//     states: x?.states
//   }))

//   const places2 = countries?.find(x => x?.value === (country || "nigeria"))?.states?.map(i => ({
//     title: i?.name,
//     value: i?.name?.toLowerCase(),
//     listValue: i?.subdivision?.map(i => ({
//       title: i,
//       value: i,
//     }))
//   })) || []

//   const teamOptions = [];

//   for (let i = 0; i < 100; i++) {
//     const newObj = {
//       title: `Team ${i + 1}`,
//       value: i + 1,
//     };
//     teamOptions.push(newObj);
//   }




//   const fileInputRefs = useRef({});
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const streamRef = useRef(null);
//   const [hasPhoto, setHasPhoto] = useState(false);

//   const [showCamera, setShowCamera] = useState(false);


  



//   const create = async () => {
//     setCreatingAsset(true);

//     const locationx = await fetchUserLocation()

//     const { latitude, longitude } = locationx

//     const config = {
//       headers: {
//         authorization: `Bearer ${localStorage.getItem('PRV1TK')}`,
//         'Content-Type': 'multipart/form-data',
//       },
//     };

//     const formData = new FormData();
//     formData.append('client', values.client);
//     formData.append('brand', values.brand);
//     formData.append('type', values.type);
//     formData.append('category', values.category);
//     formData.append('additionalInfo', values.additionalInfo || 'nil');
//     formData.append('image', values.image);
//     if (!isEdit && !auto) {
//       formData.append('longitude', longitude)
//       formData.append('latitude', latitude)
//     }
//     if(!auto) {
//     formData.append('buildingNo', values.buildingNo)
//     formData.append('streetName', values.streetName)
//     formData.append('country', country || countries?.find(x => x?.code === defaultCountry)?.title)
//     formData.append('city', town)
//     formData.append('LGA', area)
//     formData.append('state', state)
//     formData.append('locationName', values?.locationName);
//     }
//     if(auto) {
//       formData.append('outlet', location?.value);
//     }

//     try {
//       if (isEdit) {
//         await axios.patch(`${url}/assets/edit/${idx}`, formData, config);
//       } else {
//         await axios.post(`${url}/assets/field/register`, formData, config);
//       }

//       setTriggerClose('close' + (Math.random()))
//       setRefetch(Math.random());
//       setValues(defaultState);
//       toast({
//         description: 'Registration submitted for review',
//         status: "success",
//         position: 'top'
//       });
//     } catch (error) {
//       const message = error?.response?.data?.message || 'Something went wrong';
//       toast({
//         title: "An Error Occurred",
//         description: message,
//         status: "error",
//       });
//     } finally {
//       setCreatingAsset(false);
//     }
//   };


//   useEffect(() => {
//     const getCameraStream = async () => {
//       try {
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         videoRef.current.srcObject = stream;
//         streamRef.current = stream;
//         await videoRef.current.play();
//       } catch (err) {
//         console.error('Error accessing camera: ', err);
//       }
//     };

//     if (showCamera) {
//       getCameraStream();
//     } else {
//       stopStream();
//     }

//     return () => stopStream();
//   }, [showCamera]);


//   const pickLiveImageFile = (name, file) => {
//     setValues((prev) => ({
//       ...prev,
//       [name]: file
//     }));
//     setShowCamera(false);
//     stopStream();
//   };

//   const stopStream = () => {
//     if (streamRef.current) {
//       streamRef.current.getTracks().forEach(track => track.stop());
//       streamRef.current = null;
//     }
//   };


//   const takePicture = (name) => {
//     const width = videoRef.current.videoWidth;
//     const height = videoRef.current.videoHeight;

//     canvasRef.current.width = width;
//     canvasRef.current.height = height;

//     const ctx = canvasRef.current.getContext('2d');
//     ctx.drawImage(videoRef.current, 0, 0, width, height);

//     setHasPhoto(true);

//     canvasRef.current.toBlob(blob => {
//       const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
//       pickLiveImageFile(name, file);
//     }, 'image/jpeg');
//   };

//   const clearPhoto = () => {
//     const ctx = canvasRef.current.getContext('2d');
//     ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
//     setHasPhoto(false);
//   };

//   useEffect(() => {
//     if (showCamera) {
//       const constraints = {
//         video: {
//           facingMode: { exact: "environment" }
//         }
//       };

//       navigator.mediaDevices.getUserMedia(constraints)
//         .then(stream => {
//           videoRef.current.srcObject = stream;
//           videoRef.current.play();
//         })
//         .catch(err => {
//           console.error("Error accessing camera: ", err);
//         });
//     }
//   }, [showCamera]);


//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setValues((prev) => ({
//       ...prev,
//       [name]: value
//     }));
//   }

//   const handleInputChange = (value) => {
//     // console.log(value)
//     setSearchVal(value)
//       if(value?.length < 3 || value === "") {
//         setStores([])
//       }
      
//   }

//   const selectedBrandObject = (values?.brand && names?.find((item) => (
//     item.brand === values?.brand
//   ))) || null

//   const selectedCategoryObject = (values?.category && selectedBrandObject?.categories?.find(item => (
//     item.category === values?.category
//   ))) || null


//   const storeOptions = stores?.map(x => ({
//     label: x?.name,
//     value: x?._id
//   }))




//   return (
//     <div className={classes.bigContainer}>
//       {
//         loadingx ?
//           <div className={classes.loader_con}>
//             <Spinner />
//           </div>
//           :
//           <div className={classes.formContainer}>

//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Select a client</label>
//               <div className={classes.inputContainer}>
//                 <select className={classes.select} name='client' onChange={handleChange} >
//                   <option value={''}>
//                     {isEdit ? clientOptions?.find(x => x?.value === idData?.client)?.title : 'Organization*'}
//                   </option>

//                   {
//                     clientOptions?.map((item, index) => (
//                       <option key={index} value={item.value}>{item?.title}</option>
//                     ))
//                   }

//                 </select>
//               </div>
//             </div>


//             {
//               names &&
//               <div className={classes.inputContainerCon}>
//                 <label className={classes.label}>Select a brand</label>
//                 <div className={classes.inputContainer}>
//                   <select className={classes.select} name="brand" value={values.brand} onChange={handleChange} >
//                     <option value={''}>
//                       {isEdit ? idData?.brand : 'Brand*'}
//                     </option>
//                     {
//                       names?.map((item, index) => (
//                         <option key={index}>{item?.brand}</option>
//                       ))
//                     }
//                   </select>
//                 </div>
//               </div>
//             }



//             {
//               (selectedBrandObject || values?.brand) &&

//               <div className={classes.inputContainerCon}>
//                 <label className={classes.label}>Select a category</label>
//                 <div className={classes.inputContainer}>
//                   <select className={classes.select} name="category" value={values.category} onChange={handleChange} >
//                     <option value={''}>
//                     {isEdit ? idData?.category : 'Category*'}
//                     </option>
//                     {
//                       selectedBrandObject?.categories?.sort((a, b) => a.category.localeCompare(b.category))?.map((item, index) => (
//                         <option key={index}>{item?.category}</option>
//                       ))
//                     }
//                   </select>
//                 </div>
//               </div>

//             }


//             {
//               (selectedCategoryObject || values.category) &&
//               <div className={classes.inputContainerCon}>
//                 <label className={classes.label}>Select a type</label>
//                 <div className={classes.inputContainer}>
//                   <select className={classes.select} name="type" value={values.type} onChange={handleChange} >
//                     <option value={''}>
//                     {isEdit ? idData?.type : 'Type'}
//                     </option>
//                     {
//                       selectedCategoryObject?.types?.map((item, index) => (
//                         <option key={index}>{item}</option>
//                       ))
//                     }
//                   </select>
//                 </div>
//               </div>

//             }

//             {auto ?
//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Outlet </label>
//             <SearchableSelect
//                 options={storeOptions}
//                 onChange={(e) => setLocation(e)}
//                 onInputChange={handleInputChange}
//                 placeholder={'Select Outlet'}
//                 name="locationName"
//                 height="300px"
//             />
//             <Flex justifyContent={"flex-start"}>
//               <Text fontSize={".8rem"} mt=".3rem">Cant find your location? <span onClick={() => setAuto(false)} style={{fontWeight: '500', cursor: "pointer", textDecoration: 'underline'}}>Add new</span></Text>
//             </Flex>
//             </div>
//             :
//             <>
//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Location Name </label>
//               <div className={classes.inputContainer}>
//                 <input
//                   type='text'
//                   className={classes.select}
//                   name='locationName'
//                   onChange={handleChange}
//                 />
//               </div>
//             </div>
//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Building Number </label>
//               <div className={classes.inputContainer}>
//                 <input
//                   type='number'
//                   inputMode="numeric"
//                   pattern="[0-9]*"
//                   className={classes.select}
//                   name='buildingNo'
//                   onChange={handleChange}
//                 />
//               </div>
//             </div>

//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Street Name </label>
//               <div className={classes.inputContainer}>
//                 <input
//                   type='text'
//                   className={classes.select}
//                   name='streetName'
//                   onChange={handleChange}
//                 />
//               </div>
//             </div>


//             <div className={''}>
//               <label className={classes.label}>Country </label>
//               <CustomSelect
//                 required
//                 options={[{title: countries?.find(x => x?.code === defaultCountry)?.title, value: countries?.find(x => x?.code === defaultCountry)?.value}, ...countries]}
//                 onChange={e => setCountry(e.target.value)}
//                 height="62.5px !important"
//                 borderRadius="10px !important"
//               />
//             </div>

//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>State </label>
//               <CustomSelect
//                 required
//                 options={places2}
//                 placeholder={"State"}
//                 onChange={e => setState(e.target.value)}
//                 height="62.5px !important"
//                 borderRadius="10px !important"
//               />
//             </div>

//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Area </label>
//               <CustomSelect
//                 required
//                 options={places2?.filter(i => i.value === state)[0]?.listValue}
//                 placeholder={"Area"}
//                 onChange={e => setArea(e.target.value)}
//                 height="62.5px !important"
//                 borderRadius="10px !important"
//               />
//             </div>

//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Town </label>
//               <CustomInput
//                 type={"text"}
//                 required
//                 placeholder={"Town"}
//                 onChange={e => setTown(e.target.value)}
//                 height="62.5px !important"
//                 borderRadius="10px !important"
//               />
//             </div>
//             </>
// }


//             <div className={classes.inputContainerCon}>
//               <label className={classes.label}>Image </label>
//               <p onClick={() => setShowCamera(true)} className={classes.pick_location}>
//                 Click To Open Camera
//               </p>
//               {showCamera && (
//                 <div className={classes.container}>
//                   <div className={classes.video_container}>
//                     <video className={classes.video} ref={videoRef} playsInline autoPlay muted />
//                     <button className={classes.camera_icon_container} onClick={() => takePicture('image')}>Take Picture</button>
//                   </div>
//                 </div>
//               )}
//         
//               <canvas className={classes.taken_picture} ref={canvasRef} style={{ display: hasPhoto ? 'block' : 'none' }} />

              
             

//             </div>

//             <button onClick={create} className={classes.submit_response_btn}>Submit{creatingAsset && 'ting...'}</button>

//           </div>
//       }


//     </div>
//   )
// }

// export default CreateAssets;