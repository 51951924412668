import React, { useEffect, useState } from 'react'
import axiosInstance from '../../services/api';
import { buildResourceURL, formatDate } from '../../utils/helper';
import LineSkeleton from '../../components/lineSkeleton';
import EmptyState from '../../components/emptyState/emptyState';
import { Box, Flex } from '@chakra-ui/react';
import CustomTable from '../../components/customTable/customTable';
import PrimaryButton from '../../components/primaryButton/primarybutton';
import CustomDrawer from '../../components/customDrawer/customDrawer';
import CustomInput from '../../components/customInput/customInput';

const AssetLocations = () => {
    const [assets,setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState('');
    const [triggerClose, setTriggerClose] = useState('');
    const [idx, setIdx] = useState('');

    // const [startDate, setStartDate] = useState(formatDate(new Date()))
    // const [endDate, setEndDate] = useState(formatDate(new Date()))

    const [searchVal, setSearchVal] = useState('')

    const [idData, setIdData] = useState('');

    const queryParam = [
        {
            key: 'country',
            value: 'Nigeria'
        },
        {
            key: 'search',
            value: searchVal
        },
        {
            key: 'status',
            value: 'approved'
        },
        {
            key: 'sort',
            value: '-createdAt'
        },
        // {
        //     key: 'pending',
        //     value: 'true'
        // },
        // {
        //     key: 'startDate',
        //     value: startDate
        // },
        // {
        //     key: 'endDate',
        //     value: endDate
        // },
    ]

    const getAssets = async () => {
        setLoading(true);
        try {
          const { data } = await axiosInstance.get(buildResourceURL('/assets/locations/names', queryParam));
          // console.log(data?.data?.assets)
          setAssets(data?.data?.assetLocations)
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false);
        }
    };
    
      useEffect(() => {
        getAssets();
      }, [refetch]);

      const tHeaders = ['Name', 'City', 'State', 'Status', 'Client']
      const tData = assets?.map(i => ({
        ...i,
        one: i?.locationName,
        two: i?.city,
        three: i?.state,
        four: i?.pending === true ? 'pending' : 'approved',
        five: i?.client?.companyName,
        id: i?._id,
      }))

  return (
    <>
    {/* <Flex gap="1rem" mt="1rem" alignItems={"center"}>
        <Box width={"150px"}>
            <CustomInput
            type={"date"}
            required
            placeholder={"Select Date"}
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            mt="0"
            py="1rem"
            mb="0"
            borderColor="lightgray"
            fontSize=".8rem"
            />
        </Box>
        -
        <Box width={"150px"}>
            <CustomInput
            type={"date"}
            required
            placeholder={"Select Date"}
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            mt="0"
            py="1rem"
            mb="0"
            borderColor="lightgray"
            fontSize=".8rem"
            />
        </Box>
    </Flex> */}
    
        {loading ?
        <LineSkeleton num={8} />
        :
        assets?.length < 1 ? <EmptyState text={"No entries found for this day."} />
        :
        <Box marginTop={"1rem"}>
        <CustomTable
        headers={tHeaders}
        data={tData}
        setIdx2={setIdx}
        setIdx={setIdData}
        triggerClose={triggerClose}
        manage={
            <></>
        }
        />
        </Box>}
    </>
  )
}

export default AssetLocations;